<template>
    <div v-if="lineChartProps">
        <LineChart v-bind="lineChartProps" />
    </div>
</template>

<script>
import { computed } from 'vue'
import { Chart, registerables } from 'chart.js'
import { LineChart, useLineChart } from 'vue-chart-3'

Chart.register(...registerables)

export default {
    name: 'ChartLine',
    props: [ 'data', 'label', 'hideScaleX', 'hideScaleY'],
    components: { LineChart },
    setup (props) {

        const chartData = computed(() => ({
            labels: props.label,
            datasets: props.data
        }))

        const options = computed(() => ({
            scales: {
                x: {
                    display: (props.hideScaleX === 'true' ? false : true),
                    grid: {
                        display: false,
                        drawBorder: true,
                    },
                },
                y: {
                    display: (props.hideScaleY === 'true' ? false : true),
                    grid: {
                        display: false,
                        drawBorder: true,
                    },
                }
            },
            plugins: {
                legend: {
                    position: "top",
                }
            }
        }))

        const { lineChartProps, lineChartRef } = useLineChart({
            chartData: chartData, 
            options
        })

        return { lineChartProps, lineChartRef }
    }
}
</script>

<style>

</style>
<template>
    <div v-if="pieChartProps">
        <PieChart v-bind="pieChartProps" />
    </div>
</template>

<script>
import { computed } from 'vue'
import { Chart, registerables } from 'chart.js'
import { PieChart, usePieChart } from 'vue-chart-3'

Chart.register(...registerables)

export default {
    name: 'ChartPie',
    props: [ 'data', 'label', 'color' ],
    components: { PieChart },
    setup (props) {

        const chartData = computed(() => ({
            labels: props.label,
            datasets: [
                {
                    data: props.data,
                    backgroundColor: props.color,
                },
            ],
        }))

        const options = computed(() => ({
            plugins: {
                legend: {
                    position: "top",
                }
            }
        }))

        const { pieChartProps, pieChartRef } = usePieChart({
            chartData: chartData, 
            options
        })

        return { pieChartProps, pieChartRef }
    }
}
</script>

<style>

</style>
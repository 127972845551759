<template>
    <table :align="avatarAlign" border="0">
        <tr>
            <td>
                <!-- Editor or Owner -->
                <span v-if="avatarPrivilege === 'OWNER' || avatarPrivilege === 'EDITOR'">
                    <span v-if="avatarUrl">
                        <Popper v-if="avatarWidth === '30'" class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: -5px; margin-left: -7px" />
                        </Popper>
                        <Popper v-if="avatarWidth === '35'" class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: 1px; margin-left: -5px" />
                        </Popper>
                        <Popper v-if="avatarWidth === '50'" class="popperDark" arrow hover :content="avatarPrivilege">    
                            <fa icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: 5px; margin-left: -1px" />
                        </Popper>
                        <Popper v-if="avatarWidth === '60'" class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: 10px; margin-left: 5px" />
                        </Popper>
                        <Popper v-if="avatarWidth === '120'" class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 28px; margin-top: 95px; margin-left: -5px" />
                        </Popper>

                    </span>
                    <span v-else>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '30'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: -12px; margin-left: -7px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '35'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: -14px; margin-left: -5px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '50'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: -15px; margin-left: -2px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '60'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; margin-top: -16px; margin-left: 2px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '120'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 28px; margin-top: -28px; margin-left: 0px" />
                        </Popper>
                    </span>
                </span>

                <!-- IsUser, Pending, Disabled, KYC indicators -->
                <span v-if="avatarIsUser === false || avatarIsUser === 'false'">
                    <fa v-if="avatarWidth === '30'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 14px; margin-left: 22px" />
                    <fa v-if="avatarWidth === '35'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 16px; margin-left: 24px" />
                    <fa v-if="avatarWidth === '50'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 32px; margin-left: 40px" />
                    <fa v-if="avatarWidth === '60'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 40px; margin-left: 48px" />
                    <fa v-if="avatarWidth === '120'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 82px; margin-left: 95px" />
                </span>
                <span v-else-if="avatarIsActive === 'PENDING'">
                    <fa v-if="avatarWidth === '30'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 14px; margin-left: 22px" />
                    <fa v-if="avatarWidth === '35'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 16px; margin-left: 24px" />
                    <fa v-if="avatarWidth === '50'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 32px; margin-left: 40px" />
                    <fa v-if="avatarWidth === '60'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 40px; margin-left: 48px" />
                    <fa v-if="avatarWidth === '120'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 82px; margin-left: 95px" />
                </span>
                <span v-else-if="avatarIsActive === 'DISABLED' || avatarIsActive === 'EXPIRED' || avatarIsActive === 'DELETING' || avatarIsActive === 'REACTIVE'">
                    <fa v-if="avatarWidth === '30'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 14px; margin-left: 22px" />
                    <fa v-if="avatarWidth === '35'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 16px; margin-left: 24px" />
                    <fa v-if="avatarWidth === '50'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 32px; margin-left: 40px" />
                    <fa v-if="avatarWidth === '60'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 42px; margin-left: 48px" />
                    <fa v-if="avatarWidth === '120'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 82px; margin-left: 95px" />
                </span>
                <span v-else-if="avatarIsVerified === true || avatarIsVerified === 'true'">
                    <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 14px; margin-left: 22px" />
                    <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 16px; margin-left: 24px" />
                    <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 32px; margin-left: 40px" />
                    <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; margin-top: 42px; margin-left: 48px" />
                    <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; margin-top: 82px; margin-left: 95px" />
                </span>

                <!-- Upload avatar / default avatar -->
                <span v-if="avatarUrl === undefined || avatarUrl === 'undefined' || avatarUrl === null || avatarUrl === ''">
                    <fa v-if="avatarWidth === '30'" icon="user" style="width: 28px; height: 28px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '35'" icon="user" style="width: 32px; height: 32px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '50'" icon="user" style="width: 48px; height: 48px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '60'" icon="user" style="width: 58px; height: 58px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '120'" icon="user" style="width: 116px; height: 116px" class="navbar-display-name"/>
                </span>
                <span v-else>
                        <!-- <img v-if="avatarWidth === '30'" :src="avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" style="object-fit: cover"/> -->
                        <img v-if="avatarWidth === '30'" :src="'data:image/png; base64, ' + avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" alt="user avatar" style="object-fit: cover"/>
                        <img v-if="avatarWidth === '35'" :src="'data:image/png; base64, ' + avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" alt="user avatar" style="object-fit: cover"/>
                        <img v-if="avatarWidth === '50'" :src="'data:image/png; base64, ' + avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" alt="user avatar" style="object-fit: cover"/>
                        <img v-if="avatarWidth === '60'" :src="'data:image/png; base64, ' + avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" alt="user avatar" style="object-fit: cover"/>
                        <img v-if="avatarWidth === '120'" :src="'data:image/png; base64, ' + avatarUrl" class="rounded-circle" :width="avatarWidth" :height="avatarWidth" alt="user avatar" style="object-fit: cover"/>
                </span>
                
            </td>
            <td v-if="avatarDisplayName" class="align-middle" :class="avatarClass" style="font-size: 14px">{{ avatarDisplayName }}</td>
        </tr>
    </table>

</template>

<script>
import {ref, onMounted} from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'Avatar',
    components: { Popper },
    props: [ 'url', 'firstName', 'lastName', 'displayName', 'class', 'width', 'height', 'isUser', 'avatar', 'isVerified', 'isActive', 'privilege', 'align' ],
    setup (props) {

        const avatarUrl = ref(props.avatar)
        const avatarDisplayName = ref(props.displayName)
        const avatarIsUser = ref(props.isUser)
        const avatarIsVerified = ref(props.isVerified)
        const avatarIsActive = ref(props.isActive)
        const avatarPrivilege = ref(props.privilege)

        const avatarWidth = ref(props.width)
        const avatarHeight = ref(props.height)
        const avatarClass = ref(props.class)
        const avatarAlign = ref(props.align)

        const marginTop = ref(22)
        const marginLeft = ref(28)

        onMounted(() => {
            if (avatarWidth.value === '' || avatarWidth.value === undefined) {
                avatarWidth.value = '35'
            }

            if (avatarHeight.value === '' || avatarHeight.value === undefined) {
                avatarHeight.value = '35'
            }

            if (avatarAlign.value === '' || avatarAlign.value === undefined) {
                avatarAlign.value = "left"
            }

            /*
            if (avatarWidth.value === '50') {
                marginTop.value = 32
                marginLeft.value = 40
            }

            if (avatarWidth.value === '60') {
                marginTop.value = 42
                marginLeft.value = 50
            } */

            // console.info('margin', marginTop.value, marginLeft.value)
        })

        return {
            avatarIsUser, avatarUrl, avatarDisplayName, avatarIsVerified, avatarIsActive, 
            avatarClass, avatarWidth, avatarHeight, avatarAlign, avatarPrivilege, 
            marginTop, marginLeft
        }
    }
}
</script>

<style>

</style>
<template>
    <div class="row">
        <div class="col-12 fw-bold text-center">{{strTitle}}</div>
        <div class="col-12"><hr /></div>
        <div v-if="singleCol === 'true'" class="col-12 small text-center">
            <div class="fw-bold">{{strLeftTitle}}</div>
            <div>{{strLeftData}}</div>
        </div>
        <div v-else class="col-12 small">
            <table width="100%">
                <tr>
                    <td width="48%" class="text-center">
                        <div class="fw-bold">{{strLeftTitle}}</div>
                        <div>{{strLeftData}}</div>
                    </td>
                    <td width="*" class="text-center px-1">
                        <div class="d-flex" style="height: 30px;">
                            <div class="vr"></div>
                        </div>
                    </td>
                    <td width="48%" class="text-center">
                        <div class="fw-bold">{{strRightTitle}}</div>
                        <div>{{strRightData}}</div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'ReportFigureBox',
    props: [ 'title', 'leftTitle', 'leftData', 'rightTitle', 'rightData', 'singleColumn' ],
    setup (props) {

        const strTitle = ref(props.title)
        const strLeftTitle = ref(props.leftTitle)
        const strLeftData = ref(props.leftData)
        const strRightTitle = ref(props.rightTitle)
        const strRightData = ref(props.rightData)
        const singleCol = ref(props.singleColumn)

        return { strTitle, strLeftTitle, strLeftData, strRightTitle, strRightData, singleCol }
    }
}
</script>

<style>

</style>
<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Admin Report" icon="chart-line" />

    <div class="rptContainer">

        <div class="row mb-3">
            <div class="col-12 col-lg-6">
                <div class="d-flex justify-content-start">
                    <div class="filter-pill-border">
                        <span class="filter-item-pill" :class="rptType === 'sale' ? 'active' : ''" @click="rptType = 'sale'">Sale</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="rptType === 'traffic' ? 'active' : ''" @click="rptType = 'traffic'">Traffic</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="rptType === 'storage' ? 'active' : ''" @click="rptType = 'storage'">Storage</span>
                    </div>
                </div>
            </div>
            <div v-if="rptType === 'traffic'" class="col-12 col-lg-6">
                <div class="d-flex justify-content-end">
                    <div class="filter-pill-border">
                        <span class="filter-item-pill border" :class="trafficOpt === '' ? 'active' : ''" @click="trafficOpt = ''">Document</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="trafficOpt === 'package' ? 'active' : ''" @click="trafficOpt = 'package'">Package</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="trafficOpt === 'member' ? 'active' : ''" @click="trafficOpt = 'member'">Member</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sale Report -->
        <div v-if="rptType === 'sale'" class="row">
            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="FREE" leftTitle="600" leftData="SUBSCRIPTION" rightTitle="50" rightData="%" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="PERSONAL" leftTitle="360" leftData="SUBSCRIPTION" rightTitle="30" rightData="%" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="TEAM" leftTitle="240" leftData="SUBSCRIPTION" rightTitle="20" rightData="%" />
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="row p-0">
                        <div class="col-lg-6"><span class="fw-bold">Sale</span> <div class="text-grey small mb-2">Mar - Aug 2022</div></div>
                        <div class="col-lg-6 mb-4">
                            <div class="btn-group btn-group-sm float-end">
                                <button type="button" class="btn btn-outline-secondary">Day</button>
                                <button type="button" class="btn btn-secondary">Month</button>
                                <button type="button" class="btn btn-outline-secondary">Year</button>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <ChartLine :data="saleData" :label="saleLabel" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Expiring Subscription</div>
                    <div class="text-grey small mb-4">Aug 2022 - Jan 2023</div>
                    <ChartBar :data="expData" :label="expLabel" />
                </div>
            </div>

            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="ACTIVE" leftTitle="750" leftData="SUBSCRIPTION" rightTitle="75" rightData="%" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="EXPIRING" leftTitle="150" leftData="SUBSCRIPTION" rightTitle="15" rightData="%" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="EXPIRED" leftTitle="100" leftData="SUBSCRIPTION" rightTitle="10" rightData="%" />
                </div>
            </div>
        </div>
        <!-- Sale Report End -->


        <!-- Traffic Report -->
        <div v-if="rptType === 'traffic' && trafficOpt === ''" class="row">
            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="DOC & PROJ" leftTitle="10,000" leftData="ITEM" rightTitle="1,200" rightData="%" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="DOC" leftTitle="5,500" leftData="ITEM" rightTitle="55" rightData="%" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="PROJ" leftTitle="4,500" leftData="ITEM" rightTitle="45" rightData="%" />
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Traffic</div>
                    <div class="text-grey small mb-4">Mar - Aug 2022 Document & project creation</div>
                    <div>
                        <ChartBar :data="trafficData" :label="trafficLabel" />
                    </div>
                </div>
            </div>
        </div>


        <div v-if="rptType === 'traffic' && trafficOpt === 'package'" class="row">
            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="FREE" leftTitle="3,540" leftData="DOC" rightTitle="1,200" rightData="PROJ" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="PERSONAL" leftTitle="820" leftData="DOC" rightTitle="680" rightData="PROJ" />
                </div>

                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="TEAM" leftTitle="1,220" leftData="DOC" rightTitle="1035" rightData="PROJ" />
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Traffic</div>
                    <div class="text-grey small mb-4">Mar - Aug 2022 document creation</div>
                    <div>
                        <ChartBar :data="trafficPackageData" :label="trafficPackageLabel" />
                    </div>
                </div>
            </div>
        </div>


        <div v-if="rptType === 'traffic' && trafficOpt === 'member'" class="row">
            <div class="col-lg-3 d-grid">
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="MEMBER" leftTitle="4,750" leftData="PERSON" singleColumn="true" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="KYC MEMBER" leftTitle="1,663" leftData="PERSON" rightTitle="35" rightData="%" />
                </div>
                <div class="rptBox shadow-sm">
                    <ReportFigureBox title="NON-KYC MEMBER" leftTitle="3,087" leftData="PERSON" rightTitle="65" rightData="%" />
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Traffic</div>
                    <div class="text-grey small mb-4">Mar - Aug 2022 document creation</div>
                    <div>
                        <ChartBar :data="trafficMemberData" :label="trafficMemberLabel" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Traffic Report End -->


        <!-- Storage Report -->
        <div v-if="rptType === 'storage'" class="row">
            <div class="col-lg-8 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Storage</div>
                    <div class="text-grey small mb-4">Mar - Aug 2022</div>
                    <ChartLine :data="storeMonData" :label="storeMonLabel" />
                </div>
            </div>

            <div class="col-lg-4 d-grid">
                <div class="rptBox shadow-sm">
                    <div class="fw-bold">Overview</div>
                    <div class="text-grey small mb-4"> 6,300 GB free of 8,000 GB</div>
                    <ChartPie :data="storeData" :label="storeLabel" :color="storeColor" />
                </div>
            </div>
        </div>
        <!-- Storage Report End -->

    </div>

</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

import ChartBar from '@/components/chart/ChartBar.vue'
import ChartLine from '@/components/chart/ChartLine.vue'
import ChartPie from '@/components/chart/ChartPie.vue'

import ReportFigureBox from '@/components/ReportFigureBox.vue'

export default {
    name: 'BOReport2',
    components: { Alert, TopNavigation, Popper, Avatar, ChartBar, ChartLine, ChartPie, ReportFigureBox },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const rptType = ref('sale')
        const trafficOpt = ref('')

        const trafficData = ref([])
        const trafficLabel = ref([])

        const trafficPackageData = ref([])
        const trafficPackageLabel = ref([])

        const trafficMemberData = ref([])
        const trafficMemberLabel = ref([])

        const saleData = ref([])
        const saleLabel = ref([])

        const storeMonData = ref([])
        const storeMonLabel = ref([])

        const storeData = ref([])
        const storeLabel = ref([])
        const storeColor = ref([])

        const expData = ref([])
        const expLabel = ref([])

        const arrMember = ref([])   // order by last login

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([trafficOpt], () => {
            console.info('watch', '')
            if (trafficOpt.value === 'package') {
                getTrafficPackage()
            }

            if (trafficOpt.value === 'member') {
                getTrafficMember()
            }
        })

        const getTraffic = () => {
            trafficData.value = [
                {
                    label: 'Document',
                    data: [ 125, 123, 150, 138, 160, 130],
                    backgroundColor: '#77CEFF',
                    borderColor: '#77CEFF',
                },
                {
                    label: 'Project',
                    data: [ 103, 100, 135, 110, 142, 112],
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                }
            ]
            trafficLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getTrafficPackage = () => {
            trafficPackageData.value = [
                {
                    label: 'Free',
                    data: [ 125, 123, 150, 138, 160, 130],
                    backgroundColor: '#77CEFF',
                    borderColor: '#77CEFF',
                },
                {
                    label: 'Personal',
                    data: [ 83, 213, 58, 83, 62, 31],
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                },
                {
                    label: 'Team',
                    data: [ 76, 203, 105, 122, 198, 53],
                    backgroundColor: '#123E6B',
                    borderColor: '#123E6B',
                }
            
            ]
            trafficPackageLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getTrafficMember = () => {
            trafficMemberData.value = [
                {
                    label: 'KYC',
                    data: [ 125, 123, 150, 138, 160, 130],
                    backgroundColor: '#77CEFF',
                    borderColor: '#77CEFF',
                },
                {
                    label: 'Non-KYC',
                    data: [ 103, 100, 135, 110, 142, 112],
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                }
            ]
            trafficMemberLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getSale = () => {
            saleData.value = [
                {
                    label: 'Free',
                    data: [65, 59, 80, 81, 86, 55, 90],
                    fill: false,
                    backgroundColor: "#77CEFF",
                    borderColor: "#77CEFF",
                    tension: 0.4,
                },
                {
                    label: "Personal",
                    data: [28, 48, 40, 19, 56, 27, 40],
                    fill: false,
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                    tension: 0.4,
                },
                {
                    label: "Team",
                    data: [20, 35, 40, 50, 81, 35, 45],
                    fill: false,
                    backgroundColor: "#123E6B",
                    borderColor: "#123E6B",
                    tension: 0.4,
                },
            ]
            saleLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getStoreMonth = () => {
            storeMonData.value = [
                {
                    label: 'Limit',
                    data: [65, 59, 80, 81, 86, 55, 90],
                    fill: false,
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                    tension: 0.4,
                },
                {
                    label: "Used",
                    data: [28, 48, 40, 19, 56, 27, 40],
                    fill: true,
                    backgroundColor: "#77CEFF",
                    tension: 0.4,
                }
            ]
            storeMonLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        }

        const getStore = () => {
            storeData.value = [70, 30]
            storeLabel.value = ['Used %', 'Free %']
            storeColor.value = ['#0079AF', '#77CEFF']
        }

        const getExpiring = () => {
            expData.value = [
                {
                    label: 'Free',
                    data: [ 15, 12, 18, 16, 12, 10],
                    backgroundColor: '#77CEFF',
                },
                {
                    label: 'Personal',
                    data: [ 3, 20, 18, 5, 16, 8],
                    backgroundColor: '#0079AF',
                },
                {
                    label: 'Team',
                    data: [ 2, 8, 12, 6, 10, 3],
                    backgroundColor: '#123E6B',
                }
            ]
            expLabel.value = [ 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan']
        }

        getTraffic()
        getSale()
        getStoreMonth()
        getStore()
        getExpiring()

        onMounted(() => {

            arrMember.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    displayName: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    teams: ['admin', 'developer'],
                    tags: ['colleague', 'development'],
                    dateLastLogin: '2022-08-26T05:23:10.000Z',
                    progress: 20,
                    totalDoc: 25,
                    gmt: '+09:00',
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    displayName: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    teams: ['developer'],
                    tags: ['colleague', 'cs'],
                    dateLastLogin: '2022-08-26T02:00:51.000Z',
                    progress: 55,
                    totalDoc: 33,
                    gmt: '+08:00',
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    displayName: 'Grace',
                    avatar: '',
                    isVerified: false,
                    teams: [],
                    tags: [],
                    dateLastLogin: '2022-08-26T01:00:51.000Z',
                    progress: 80,
                    totalDoc: 150,
                    gmt: '+10:00',
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    displayName: 'Jason',
                    avatar: '',
                    isVerified: false,
                    teams: ['customer service'],
                    tags: ['unifi'],
                    dateLastLogin: '2022-08-23T04:00:51.000Z',
                    progress: 36,
                    totalDoc: 45,
                    gmt: '+08:00',
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    displayName: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    teams: ['admin'],
                    tags: ['colleague', 'support'],
                    dateLastLogin: '2022-07-19T07:40:51.000Z',
                    progress: 88,
                    totalDoc: 12,
                    gmt: '+02:00',
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    displayName: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    teams: ['developer'],
                    tags: ['colleague', 'development'],
                    dateLastLogin: '2022-05-20T07:40:51.000Z',
                    progress: 0,
                    totalDoc: 0,
                    gmt: '+08:00',
                },
            ]

        })

        return {
            alert, closeAlert, router, func, store, rptType, trafficOpt, 
            trafficData, trafficLabel, trafficPackageData, trafficPackageLabel, trafficMemberData, trafficMemberLabel, 
            saleData, saleLabel, storeMonData, storeMonLabel, 
            storeData, storeLabel, storeColor, expData, expLabel, 
            arrMember, 
        }
    }
}
</script>
// npm i vue-chart-3 chart.js
// https://www.npmjs.com/package/vue-chart-3
// https://vue-chart-3.netlify.app/guide/#introduction

// chart styling
https://www.chartjs.org/docs/latest/axes/styling.html

// https://codesandbox.io/search?refinementList%5Btemplate%5D=&refinementList%5Bnpm_dependencies.dependency%5D%5B0%5D=vue-chart-3&page=1&configure%5BhitsPerPage%5D=12

// chart UI ref
// https://coreui.io/vue/#live-preview

// sample ui - report
// https://coreui.io/vue/#live-preview

<style>
.rptContainer {
  width: 96%; 
  margin: 105px auto 20px auto;
}

.rptBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--chart-bg);
}

@media (max-width: 500px) {
    .rptContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }

}
/* 
.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
} */

</style>